import React, { useState } from 'react';
import axios from 'axios';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function CulturalSensitivity() {
    const [text, setText] = useState('');
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.post('/api/cultural_sensitivity', { text });
            setResult(response.data.result);
        } catch (error) {
            console.error("Error calling API", error);
            setError("An error occurred while analyzing the text. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-ai-center" style={{ marginTop: '2rem' }}>
            <Card title="Cultural Sensitivity Checker" style={{ width: '80%', marginBottom: '2em' }}>
                <p>
                    This tool helps you evaluate your text for cultural sensitivity.
                    It uses AI to identify potentially insensitive language or content that might be
                    misinterpreted across different cultural contexts.
                </p>
                <p>
                    <strong>How to use:</strong>
                    <ol>
                        <li>Enter your text in the input box below.</li>
                        <li>Click the "Check Sensitivity" button.</li>
                        <li>Review the AI-generated analysis for cultural sensitivity insights.</li>
                    </ol>
                </p>
                <Message severity="info" text="Remember, this tool is meant to assist, not replace, human judgment. Always consider the specific context and audience of your communication." />
            </Card>

            <form onSubmit={handleSubmit} className="p-d-flex p-flex-column p-ai-center" style={{ width: '80%' }}>
                <div className="p-field p-fluid" style={{ width: '100%', marginBottom: '1rem' }}>
                    <label htmlFor="sensitivityText">Enter text to check for cultural sensitivity:</label>
                    <InputTextarea
                        id="sensitivityText"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Type or paste your text here..."
                        rows={5}
                        style={{ width: '100%' }}
                        autoResize
                    />
                </div>
                <div className="p-mt-3">
                    <Button
                        label="Check Sensitivity"
                        icon="pi pi-globe"
                        onClick={handleSubmit}
                        className="p-button-primary"
                        disabled={isLoading || !text.trim()}
                    />
                </div>
            </form>

            {isLoading && (
                <div className="p-mt-3">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                </div>
            )}

            {error && (
                <div className="p-mt-3" style={{ width: '80%' }}>
                    <Message severity="error" text={error} />
                </div>
            )}

            {result && !isLoading && (
                <Card title="Sensitivity Analysis" style={{ width: '80%', marginTop: '2em' }}>
                    <p>
                        Here's an analysis of your text's cultural sensitivity. This assessment looks at potential
                        cultural misunderstandings, insensitive language, or content that might be interpreted
                        differently across cultures. Use these insights to refine your message for a diverse audience.
                    </p>
                    <InputTextarea
                        value={result}
                        rows={5}
                        readOnly
                        style={{ width: '100%', backgroundColor: '#f4f4f4' }}
                        autoResize
                    />
                </Card>
            )}
        </div>
    );
}

export default CulturalSensitivity;