import React, { useState } from 'react';
import axios from 'axios';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Message } from 'primereact/message';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';


function RewriteInclusive() {
    const [text, setText] = useState('');
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        try {
            const response = await axios.post('/api/rewrite_inclusive', { text });
            const processedText = response.data.result.replace(/\n/g, '\n');  // Handling new line characters
            setResult(processedText);
        } catch (error) {
            console.error("Error calling API", error);
            setError("An error occurred while analyzing the text. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-ai-center p-p-4">
            <Card title="Rewrite Text to be More Inclusive" style={{ width: '80%', marginBottom: '2em' }}>
                <p>
                    This tool helps you rewrite your text to be more inclusive and sensitive to diverse audiences.
                    It uses AI to suggest changes that avoid potentially biased or exclusive language,
                    making your communication more respectful and accessible to all.
                </p>
                <p>
                    <strong>How to use:</strong>
                    <ol>
                        <li>Enter your original text in the input box below.</li>
                        <li>Click the "Rewrite" button.</li>
                        <li>Review the AI-suggested inclusive version in the result box.</li>
                    </ol>
                </p>
            </Card>

            <div className="p-field p-fluid" style={{ width: '80%' }}>
                <label htmlFor="inputText">Enter your text:</label>
                <InputTextarea
                    id="inputText"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    rows={6}
                    autoResize
                    placeholder="Type or paste your text here..."
                    style={{ width: '100%' }}
                />
            </div>

            <div className="p-mt-3">
                <Button
                    label="Rewrite"
                    icon="pi pi-refresh"
                    onClick={handleSubmit}
                    className="p-button-primary"
                    loading={isLoading}
                />
            </div>

            {isLoading && (
                <div className="p-mt-3">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                </div>
            )}

            {error && (
                <div className="p-mt-3" style={{ width: '80%' }}>
                    <Message severity="error" text={error} />
                </div>
            )}

            {result && (
                <Card title="Inclusive Version" style={{ width: '80%', marginTop: '2em' }}>
                    <p>
                        Here's a suggested rewrite of your text, aiming for more inclusive language.
                        Please review and adjust as needed to ensure it maintains your intended meaning.
                    </p>
                    <InputTextarea
                        value={result}
                        rows={6}
                        autoResize
                        readOnly
                        style={{ width: '100%', backgroundColor: '#f4f4f4' }}
                    />
                </Card>
            )}
        </div>
    );
}

export default RewriteInclusive;