import React, { useState } from 'react';
import axios from 'axios';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function AnalyzeFeedback() {
    const [text, setText] = useState('');
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post('/api/analyze_feedback', { text });
            setResult(response.data.result);
        } catch (error) {
            console.error("Error calling API", error);
            setResult("An error occurred while analyzing the feedback. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="p-d-flex p-flex-column p-ai-center" style={{ marginTop: '2rem' }}>
            <Card title="Analyze Feedback" style={{ width: '80%', marginBottom: '2em' }}>
                <p>
                    This tool helps you analyze feedback to ensure it's constructive, clear, and sensitive.
                    It uses AI to evaluate the tone, clarity, and potential impact of your feedback,
                    helping you communicate more effectively and considerately.
                </p>
                <p>
                    <strong>How to use:</strong>
                    <ol>
                        <li>Enter the feedback text you want to analyze in the input box below.</li>
                        <li>Click the "Analyze" button.</li>
                        <li>Review the AI-generated analysis in the result box.</li>
                    </ol>
                </p>
            </Card>

            <form onSubmit={handleSubmit} className="p-d-flex p-flex-column p-ai-center" style={{ width: '80%' }}>
                <div className="p-field p-fluid" style={{ width: '100%', marginBottom: '1rem' }}>
                    <label htmlFor="feedbackText">Enter feedback text:</label>
                    <InputTextarea
                        id="feedbackText"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Type or paste your feedback here..."
                        rows={5}
                        style={{ width: '100%' }}
                        autoResize
                    />
                </div>
                <div className="p-mt-3">
                    <Button
                        label="Analyze"
                        icon="pi pi-chart-bar"
                        onClick={handleSubmit}
                        className="p-button-primary"
                        disabled={isLoading || !text.trim()}
                    />
                </div>
            </form>

            {isLoading && (
                <div className="p-mt-3">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                </div>
            )}

            {result && !isLoading && (
                <Card title="Feedback Analysis" style={{ width: '80%', marginTop: '2em' }}>
                    <p>
                        Here's an analysis of your feedback. This assessment looks at the tone, clarity,
                        and potential impact of your message. Use these insights to refine your feedback
                        for maximum effectiveness and sensitivity.
                    </p>
                    <InputTextarea
                        value={result}
                        rows={5}
                        readOnly
                        style={{ width: '100%', backgroundColor: '#f4f4f4' }}
                        autoResize
                    />
                </Card>
            )}
        </div>
    );
}

export default AnalyzeFeedback;