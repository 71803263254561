import React from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';

function Home() {
    const navigate = useNavigate();

    return (
        <div className="p-d-flex p-jc-center p-p-4">
            <div style={{ width: '80%' }}>
                <Card title="Welcome to Woke.Christmas!" subTitle="Empowering Thoughtful Communication with Anthropic AI" style={{ marginBottom: '2em' }}>
                    <p>
                        Woke.Christmas is designed to help you rewrite and analyze your text to ensure it aligns with modern standards of inclusivity, cultural sensitivity, and fairness.
                        By leveraging <strong><a href="https://www.anthropic.com/" target="_blank" rel="noopener noreferrer">Anthropic AI's</a> Claude</strong> model, we assist you in refining your words to avoid biases and promote constructive communication.
                    </p>
                    <p>
                        Whether you're looking to improve the inclusivity of your text, ensure feedback is clear and positive, or check your content for cultural sensitivity,
                        this tool is here to help. Our AI-driven approach ensures that your message is in line with progressive values and sensitive to different perspectives.
                    </p>
                </Card>

                <Card title="How It Works" subTitle="Harnessing the Power of Claude AI" style={{ marginBottom: '2em' }}>
                    <p>
                        <a href="https://www.anthropic.com/product" target="_blank" rel="noopener noreferrer">Claude</a>, developed by <a href="https://www.anthropic.com/" target="_blank" rel="noopener noreferrer">Anthropic AI</a>, is a state-of-the-art language model designed to assist with thoughtful, ethical, and responsible communication.
                        The tool works by analyzing the text you provide and offering suggestions or rewrites that aim to be more inclusive and aware of cultural differences.
                    </p>
                    <p>
                        Each feature of this tool serves a specific purpose:
                    </p>
                    <ul>
                        <li><strong>Rewrite Inclusively:</strong> Transform your text to be more inclusive by removing potential biases and ensuring everyone feels represented.</li>
                        <li><strong>Analyze Feedback:</strong> Receive constructive feedback on the clarity, tone, and impact of your writing, focusing on inclusivity and accessibility.</li>
                        <li><strong>Review Cultural Sensitivity:</strong> Ensure your content is free from culturally insensitive language and potential offenses.</li>
                    </ul>
                </Card>

                <Card title="Try It Now!" subTitle="Get Started with One of Our Tools" style={{ marginBottom: '2em' }}>
                    <p>
                        Ready to give it a try? Choose a tool below and start improving your writing with the help of Claude AI. We're here to make sure
                        your communication is progressive, thoughtful, and free from harmful biases.
                    </p>
                    <div className="p-d-flex p-jc-between p-mt-4">
                        <Button label="Rewrite Inclusively" icon="pi pi-pencil" className="p-button-primary" onClick={() => navigate('/rewrite-inclusive')} />
                        <Button label="Analyze Feedback" icon="pi pi-comments" className="p-button-secondary" onClick={() => navigate('/analyze-feedback')} />
                        <Button label="Review Cultural Sensitivity" icon="pi pi-globe" className="p-button-help" onClick={() => navigate('/cultural-sensitivity')} />
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default Home;
