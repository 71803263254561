import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Menubar } from 'primereact/menubar';
import { Button } from 'primereact/button';
import RewriteInclusive from './RewriteInclusive';
import AnalyzeFeedback from './AnalyzeFeedback';
import CulturalSensitivity from './CulturalSensitivity';
import Home from './Home';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

function App() {
  const items = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      template: (item) => <Link to="/" className="p-menuitem-link">{item.icon && <span className={item.icon}></span>} {item.label}</Link>
    },
    {
      label: 'Rewrite Inclusive',
      icon: 'pi pi-pencil',
      template: (item) => <Link to="/rewrite-inclusive" className="p-menuitem-link">{item.icon && <span className={item.icon}></span>} {item.label}</Link>
    },
    {
      label: 'Analyze Feedback',
      icon: 'pi pi-comments',
      template: (item) => <Link to="/analyze-feedback" className="p-menuitem-link">{item.icon && <span className={item.icon}></span>} {item.label}</Link>
    },
    {
      label: 'Cultural Sensitivity',
      icon: 'pi pi-globe',
      template: (item) => <Link to="/cultural-sensitivity" className="p-menuitem-link">{item.icon && <span className={item.icon}></span>} {item.label}</Link>
    },
  ];

  return (
    <Router>
      <div>
        <Menubar model={items} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/rewrite-inclusive" element={<RewriteInclusive />} />
            <Route path="/analyze-feedback" element={<AnalyzeFeedback />} />
            <Route path="/cultural-sensitivity" element={<CulturalSensitivity />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;